// define a mixin object
import {log} from "@/shared/utils";
import {
    getAdvertList,
    getUserActiveAdvertList,
    getAdvertListArchive,
    getAdvertListDraft,
    getAdvertByID,
    copyAdvertByID,
    archiveAdvertByID,
    restoreAdvertByID,
    getAdvertDescriptionByTemplateID,
    getUserBusinessUnits,
    createNewAdvert,
    saveAdvertByID,
    publishAdvertByID,
} from "@/shared/dataservice";
import {showDefaultServerErrorMessage} from "@/shared/utils";

import {mapState} from "vuex";

export const advertActionsMixin = {
    data() {
        return {
            advertData: {}
        }
    },
    computed: {
        ...mapState(['section']), // imports from store
        sectionPrefix() {
            return this.$store.state.section;
        },
    },
    methods: {
        addSectionPrefix(string) {
            return this.sectionPrefix + "" + string;
        },
        async loadDraftAdvertsList(page = 1, filter = []) {
            let result = await getAdvertListDraft(page, filter);

            log(["Loaded draft adverts", page, result, filter]);

            this.draftTableData = result.data.data;
            this.draftTablePagination = result.data.meta;
        },
        async loadPendingAdvertsList(page = 1, filter = []) {
            let result = await getAdvertList(false, page, filter);

            log(["Loaded pending adverts", false, page, result, filter]);

            this.pendingTableData = result.data.data;
            this.pendingTablePagination = result.data.meta;
        },
        async loadActiveAdvertsList(page = 1, filter = []) {
            let result = await getAdvertList(true, page, filter);

            log(["Loaded active adverts", true, page, result, filter]);

            this.activeTableData = result.data.data;
            this.activeTablePagination = result.data.meta;
        },
        async loadActiveAdvertsListCurrentUser(page = 1, filter = []) {
            let result = await getUserActiveAdvertList(page, filter);

            log(["Loaded user active adverts", page, result, filter]);

            this.activeTableData = result.data.data;
            this.activeTablePagination = result.data.meta;
        },
        async loadArchiveAdvertsList(page = 1, filter = []) {
            let result = await getAdvertListArchive(page, filter);

            log(["Loaded archive adverts", page, result, filter]);

            this.archiveTableData = result.data.data;
            this.archiveTablePagination = result.data.meta;
        },
        async loadAdvertDescriptionByID(templateID = -1) {

            log(["Getting advert description from schema with template id", templateID]);

            let result = await getAdvertDescriptionByTemplateID(templateID);

            this.advertData = result.data.data;
        },
        async loadAdvertByID(id) {

            log(["Getting advert by id", id]);

            let result = await getAdvertByID(id);

            this.advertData = result.data.data;
        },
        async saveAdvert(){
            log(["Save advert (General)", this.advertData]);

            if(this.getAdvertID().toString() === "0"){
                return this.addAdvert(this.advertData);
            }
            else
            {
                return this.updateAdvertByID(this.advertData);
            }
        },
        async addAdvert(advertData){
            log(["Adding advert", advertData]);

            let result = await createNewAdvert(advertData);

            log(["Adding advert result - no auto redirect from here", result]);

            if(result.status === 201){
                return result.data.data.id;
            }
            else
            {
                return false;
            }
        },
        async updateAdvertByID(advertData){
            log(["Update advert by ID", advertData.id, advertData]);

            let result =  await saveAdvertByID(advertData.id, advertData);

            log(["Update advert by ID result", result]);

            if(result.status === 200){
                return true;
            }
            else
            {
                return false;
            }
        },
        async publishAdvert(advertID){
            log(["Publish this advert (id provided): ", advertID]);

            let result =  await publishAdvertByID(advertID);

            log(["Publish advert by ID result", result]);

            if(result.status === 200){
                return true;
            }
            else
            {
                return false;
            }
        },
        async loadBusinessUnits() {
            let result = await getUserBusinessUnits();
            log(["Got advert business units", result]);
            return result.data.data;
        },

        /**
         * UTILS
         */

        /**
         * ADVERT GETTERS
         */
        getAdvertID() {
            return Object(this.advertData).hasOwnProperty("id") ? this.advertData.id : "";
        },
        getAdvertName() {
            return Object(this.advertData).hasOwnProperty("name") ? this.advertData.name : "";
        },
        getAdvertProperties(){
            return Object(this.advertData).hasOwnProperty("properties") ? Object.entries(this.advertData.properties).sort((a, b) => {  return a[1].order - b[1].order; }) : {};
        },
        getAdvertArchived(){
            return Object(this.advertData).hasOwnProperty("archived") ? this.advertData.archived : false;
        },
        getAdvertLiveDate(){
            return Object(this.advertData).hasOwnProperty("liveDate") ? this.advertData.liveDate : "";
        },
        getAdvertExpiryDate(){
            return Object(this.advertData).hasOwnProperty("expiryDate") ? this.advertData.expiryDate : "";
        },
        getAdvertPrivacy(){
            return Object(this.advertData).hasOwnProperty("isPrivate") ? this.advertData.isPrivate : false;
        },
        getAdvertPublished(){
            return Object(this.advertData).hasOwnProperty("published") ? this.advertData.published : false;
        },
        getAdvertPublishFormats() {
            return Object(this.advertData).hasOwnProperty("enabledPublishedFormat") ? this.advertData.enabledPublishedFormat : [];
        },

        /**
         * ADVERT SETTERS
         */
        setAdvertID(id) {
            this.advertData.id = id;
        },
        setAdvertName(name){
            this.advertData.name = name;
        },
        setAdvertBusinessUnitID(id){
            log(["Setting business unit id", id]);
            this.advertData.businessUnitId = id;
        },
        setAdvertLiveDate(date){
            this.advertData.liveDate = date;
        },
        setAdvertExpiryDate(date){
            this.advertData.expiryDate = date;
        },
        setAdvertPrivacy(privacy){
            this.advertData.isPrivate = privacy ? true : false;
        },
        setAdvertPropertyValueByAlias(alias, value){
            log(["set advert property value by alias: ", alias, value]);
            if(Object(this.advertData.properties).hasOwnProperty(alias) && this.advertData.properties[alias].hasOwnProperty("value")){
                this.advertData.properties[alias].value.value = value;
            }
        },

        /**
         * BUSINESS UNITS IDS
         */
        getAdvertBusinessUnitID() {
            return Object(this.advertData).hasOwnProperty("businessUnitId") ? this.advertData.businessUnitId : "";
        },
        parseBusinessUnitsToSelectOptions(businessUnits) {
            return businessUnits.map(function(row) {
                return {
                    value: row.id,
                    text: row.name,
                };
            })
        },

        /**
         * ACTIONS
         */
         copyAdvert: async function (id, successRouteName) {

            let result = await copyAdvertByID(id);

            log(["Copy Advert", id, successRouteName, result]);

            if(result.status === 200){
                this.$router.push( { name: successRouteName, params: { 'id' : result.data.data.id, 'message' : "iscopy" } } );
            }
            else
            {
                showDefaultServerErrorMessage("Copy advert");
            }
        },
        archiveAdvert: async function (id, successMethodName) {
            let result = await archiveAdvertByID(id);

            log(["Archive Advert", id, result]);

            if(result.status === 200){
                this[successMethodName]();
            }
            else
            {
                showDefaultServerErrorMessage("Archive Advert");
            }
        },
        restoreAdvert: async function (id, successMethodName) {
            let result = await restoreAdvertByID(id);

            log(["Restore Advert", id, result]);

            if(result.status === 200){
                this[successMethodName]();
            }
            else
            {
                showDefaultServerErrorMessage("Restore Advert");
            }
        },
    },
}